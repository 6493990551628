<style>
.v-text-field input {
  font-size: 0.75em;
}
</style>
<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-flower</v-icon>
          FINCAS
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="busqueda"
          @click:append="cargarLista()"
          @keyup.enter="cargarLista()"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-2"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-2"
          v-model="incInac"
          label="Incluir inactivos"
          @keyup.enter="cargarLista()"
          @change="cargarLista()"
          single-line
        >
        </v-checkbox>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :options.sync="options"
        hide-default-footer
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn icon color="green" @click="abrirForm(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- FORM FINCA  -->
    <v-dialog
      v-model="dialogFinca"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-snackbar
          v-model="sbFinca"
          :timeout="timeout"
          :top="true"
          :absolute="true"
          color="#333333"
        >
          {{ mensajeSbFinca }}
          <v-btn color="green" text small @click="sbFinca = false">
            Cerrar
          </v-btn>
        </v-snackbar>
        <v-card height="auto">
          <v-toolbar height="50" fixed flat color="primary" dark>
            <v-btn icon dark @click="dialogFinca = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitulo }}</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical v-model="tabFinca">
            <v-tab>
              <v-icon left> mdi-account </v-icon>
              Info General
            </v-tab>
            <v-tab>
              <v-icon left> mdi-contacts </v-icon>
              Contactos
            </v-tab>
            <v-tab>
              <v-icon left> mdi-account-key </v-icon>
              Usuario
            </v-tab>

            <v-tab>
              <v-icon left> mdi-account-settings </v-icon>
              Acceso
            </v-tab>

            <v-tab-item>
              <v-card-text flat class="pt-2">
                <v-row class="mt-0 mb-0">
                  <v-col class="pt-2 pb-0" cols="6">
                    <v-text-field
                      label="Razón social "
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_NOMBRE"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pt-1 pb-1" cols="6">
                    <v-text-field
                      label="Nombre comercial "
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_NOM_COMER"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      label="RUC "
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_RUC"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      label="Representante Legal "
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_REPRE"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-text-field
                      label="Dirección "
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_DIR"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      label="Teléfono 1"
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_TEL"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      label="Teléfono 2"
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_TEL2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-text-field
                      label="Email"
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_EMAIL1"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      label="Ciudad"
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_CIUDAD"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      label="Codigo postal"
                      :readonly="lectura"
                      v-model="datosFinca.ENTE_COD_POSTAL"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="4">
                    <v-autocomplete
                      class="mt-0"
                      label="País"
                      :items="paises"
                      v-model="datosFinca.ENTE_PAIS"
                      item-text="NOMBRE"
                      item-value="PAIS_CODIGO"
                      @change="setPaisCodigo()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="2">
                    <v-text-field
                      class="mt-0"
                      label="Código"
                      readonly
                      v-model="datosFinca.ENTE_PAIS_COD"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="4">
                    <v-autocomplete
                      class="mt-0"
                      label="Provincia"
                      :items="provincias"
                      v-model="datosFinca.ENTE_ESTADO"
                      item-text="GEO_NAME"
                      item-value="GEO_CODE"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-col class="text-center">
                  <v-btn color="primary" small @click="guardarFinca()">
                    <v-icon>mdi-content-save</v-icon> Guardar
                  </v-btn>
                  &nbsp;&nbsp;
                  <v-btn @click="dialogFinca = !dialogFinca" small>
                    <v-icon>mdi-cancel</v-icon> Cancelar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-tab-item>
            <!--CONTACTOS-->
            <v-tab-item>
              <v-card flat class="mx-10">
                <v-toolbar elevation="1">
                  <v-toolbar-title>Contactos</v-toolbar-title>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-btn
                    small
                    color="blue"
                    dark
                    @click="abrirDialogContacto(null)"
                    ><v-icon left>mdi-plus</v-icon>Agregar</v-btn
                  >
                </v-toolbar>
                <v-data-table
                  :headers="headersContactos"
                  :items="listaContactos"
                  :items-per-page="itemsPerPage"
                  :options.sync="options"
                  hide-default-footer
                  :loading="loadingTable"
                  class="elevation-1"
                >
                  <template v-slot:item.opcion="{ item }">
                    <v-btn
                      icon
                      title="editar contacto"
                      @click="abrirDialogContacto(item)"
                    >
                      <v-icon small color="green">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      title="borrar contacto"
                      @click="borrarContacto(item.CONTACTO_ID)"
                    >
                      <v-icon small color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <!--USUARIO-->
            <v-tab-item>
              <v-card flat class="mx-10">
                <v-toolbar flat>
                  <v-toolbar-title>Usuario para acceso web</v-toolbar-title>
                </v-toolbar>
                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      label="Email"
                      v-model="datosFinca.ENTE_EMAIL1"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      label="Usuario"
                      v-model="datosFinca.USER_NAME"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-card-actions class="mx-10">
                <v-btn @click="guardarUsuario" color="primary" small>
                  <v-icon>mdi-content-save</v-icon> Guardar
                </v-btn>

                <v-btn
                  color="primary"
                  small
                  @click="abrirFormUsuarioContrasena(datosFinca)"
                  >Cambiar contraseña</v-btn
                >
              </v-card-actions>
            </v-tab-item>

            <!--PAGOS-->
            <v-tab-item>
              <v-card flat class="mx-10">
                <v-toolbar flat>
                  <v-toolbar-title
                    >Acceso para descarga de guías hijas</v-toolbar-title
                  >
                </v-toolbar>

                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      type="date"
                      label="Desde"
                      v-model="pagos.FECHA_INICIO"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      type="date"
                      label="Hasta"
                      v-model="pagos.FECHA_FIN"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-text-field
                      type="text"
                      label="OBSERVACIONES"
                      v-model="pagos.OBSERVACIONES"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0 mb-0">
                  <v-col class="pt-0 pb-0" cols="6">
                    <v-checkbox label="Bloquear" v-model="pagoEstado">
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
              <v-card-actions class="mx-10">
                <v-btn color="primary" small @click="guardarAcceso">
                  <v-icon>mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-card>
    </v-dialog>

    <!-- DIALOG CAMBIO CONTRASEÑA USUARIO -->
    <v-dialog v-model="dialogContrasenaUsuario" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-account-key</v-icon>
          <span class="headline">Cambiar contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formContrasenaUsuario">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="duser.nombres"
                    label="Nombres"
                    :readonly="true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="duser.userName"
                    label="Usuario"
                    :readonly="true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2 = !show2"
                    :rules="reglasPass"
                    v-model="duser.password"
                    label="Ingrese la nueva Contraseña"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    v-model="duser.password2"
                    :rules="reglasPass"
                    label="Repita la contraseña"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="cambiarContrasenaUsuario">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;
            <v-btn
              small
              @click="dialogContrasenaUsuario = !dialogContrasenaUsuario"
            >
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialogo contacto -->
    <v-dialog v-model="dialogContacto" max-width="750px" persistent scrollable>
      <v-form ref="formContacto">
        <v-card>
          <v-toolbar flat color="blue" fixed height="40" dark>
            <v-toolbar-title>
              <v-icon class="mx-1">mdi-account</v-icon>
              Contacto
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogContacto = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="pa-4">
              <v-col cols="6" class="py-0">
                <v-text-field
                  :rules="requiredRule"
                  class="mt-0"
                  label="Nombres"
                  v-model="datosContacto.NOMBRE"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Ocupación"
                  v-model="datosContacto.OCUPACION"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Email 1"
                  v-model="datosContacto.CORREO1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-text-field
                  class="mt-0"
                  label="Email 2"
                  v-model="datosContacto.CORREO2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-text-field
                  class="mt-0"
                  label="Telefono oficina"
                  v-model="datosContacto.TELEFONO1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Teléfono casa"
                  v-model="datosContacto.TELEFONO2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Celular"
                  v-model="datosContacto.CELULAR1"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Otro teléfono"
                  v-model="datosContacto.CELULAR2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Dirección"
                  v-model="datosContacto.DIRECCION"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Ciudad"
                  v-model="datosContacto.CIUDAD"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-autocomplete
                  class="mt-0"
                  label="País"
                  :items="paises"
                  v-model="datosContacto.PAIS"
                  item-text="NOMBRE"
                  item-value="PAIS_CODIGO"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-textarea
                  style="font-size: 0.85em"
                  rows="2"
                  class="mt-0"
                  label="Observaciones"
                  v-model="datosContacto.OBSERVACION"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-toolbar flat>
            <v-btn @click="guardarContacto()" small color="green" dark
              ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
            >
            <v-divider vertical class="px-4"></v-divider>
            <v-btn @click="dialogContacto = false" small text
              ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<style>
.v-text-field input {
  font-size: 0.8em;
}

.v-text-field .v-label {
  font-size: 0.95em;
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "ListaFincasComponent",

  components: {},
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Finca", value: "ENTE_NOMBRE" },
      { text: "Codigo", value: "ENTE_ID" },
      { text: "RUC", value: "ENTE_RUC" },
    ],

    headersContactos: [
      { text: "Opciones", value: "opcion" },
      { text: "Nombre", value: "NOMBRE" },
      { text: "Ocupacion", value: "OCUPACION" },
      { text: "Correo", value: "CORREO1" },
      { text: "Teléfono", value: "TELEFONO1" },
      { text: "Celular", value: "CELULAR1" },
    ],
    requiredRule: [(v) => !!v || "El campo es requerido"],
    lista: [],
    options: {},
    notifications: false,
    daeEdit: false,
    busqueda: null,
    formDaeTitulo: "",

    itemsPerPage: 20,
    pageCount: 1,
    totalItems: 0,
    currentPage: 1,
    tabFinca: 0,
    dialogFinca: false,
    dialogContrasenaUsuario: false,
    formTitulo: "Finca",
    duser: {
      userName: "",
      password: "",
      password2: "",
    },
    incInac: false,
    datosFinca: { ENTE_ID: "" },
    listaContactos: [],
    usuario: {},
    pagos: {
      FECHA_INICIO: null,
      FECHA_FIN: null,
      OBSERVACIONES: null,
      ENTE_ID: null,
      PAGO_ID: null,
      ESTADO: "0",
    },
    lectura: false,

    show: false,
    show2: false,

    sbFinca: false,
    timeout: 5000,
    mensajeSbFinca: "",
    dialogContacto: false,
    datosContacto: {},
    paises: [],
    provincias: [],
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "months"]),

    ...mapGetters("access", []),

    pagoEstado: {
      get() {
        if (this.pagos.ESTADO == "0") {
          return false;
        } else {
          return true;
        }
      },

      set(newValue) {
        if (newValue == true) {
          this.pagos.ESTADO = "1";
        }

        if (newValue == false) {
          this.pagos.ESTADO = "0";
        }
      },
    },

    reglasPass() {
      const strongPattern = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.!@#\$%\^&\*])(?=.{5,})"
      );

      const mediumPpattern =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

      if (strongPattern.test(this.duser.password) == false) {
        return [
          false ||
            "La contraseña debe tener al menos 6 caractéres, al menos una letra mayúscula un número y un caractér especial",
        ];
      }

      /*if(this.duser.password.length < 5 ) {
				return [false || "La contraseña debe tener al menos 5 caractéres"]				
			}*/

      if (this.duser.password != this.duser.password2) {
        return [false || "Las contraseñas no coinciden"];
      }
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-fincas");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          inactivos: this.incInac,
          bus_nombre_finca: this.busqueda,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
          console.log(this.totalItems);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    abrirForm(fn) {
      this.tabFinca = 0;
      this.dialogFinca = true;
      if (fn == null) {
        this.datosFinca = { ENTE_ID: null };
      }
      this.cargarDatosFinca(fn.ENTE_ID);
    },
    cargarDatosFinca(fincaId) {
      this.setLoadingTable(true);
      this.setUrl("api/finca");
      this.requestApi({
        method: "GET",
        data: {
          finca_id: fincaId,
        },
      })
        .then((res) => {
          this.datosFinca = res.data.datosFinca;
          this.listaContactos = res.data.contactos;
          this.usuario = res.data.usuario;
          this.paises = res.data.paises;
          this.provincias = res.data.provincias;

          if (res.data.pagos.length > 0) {
            this.pagos = res.data.pagos[0];
          } else {
            this.pagos = [];
          }
          this.formTitulo = "Finca: " + this.datosFinca.ENTE_NOM_COMER;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    modificarFinca() {
      this.setUrl("api/modificar-finca");
      this.requestApi({
        method: "POST",
        data: {
          finca: this.datosFinca,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          //console.log(res.data.datosFinca)
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarFinca() {
      console.log(this.datosFinca);
      if (this.datosFinca.ENTE_ID == null) {
        this.crearFinca();
      } else {
        this.modificarFinca();
      }
    },
    crearFinca() {
      this.setUrl("api/crear-finca");
      this.requestApi({
        method: "POST",
        data: {
          finca: this.datosFinca,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          //console.log(res.data.datosFinca)
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });

      // this.alertNotification({
      //   param: {
      //     html: "error al guardar",
      //     timer: 3000,
      //     icon: "error",
      //     title: "Error",
      //   },
      // });
    },

    guardarUsuario() {
      this.setUrl("api/guardar-usuario-finca");
      this.requestApi({
        method: "POST",
        data: {
          ente_id: this.datosFinca.ENTE_ID,
          user_name_finca: this.datosFinca.USER_NAME,
          ente_email1: this.datosFinca.ENTE_EMAIL1,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: "Usuario guardado",
              timer: 3000,
            },
          });
          //console.log(res.data.datosFinca)
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarAcceso() {
      this.setUrl("api/guardar-acceso-finca");
      this.requestApi({
        method: "POST",
        data: {
          pago_id: this.pagos.PAGO_ID,
          ente_id:
            this.pagos.ENTE_ID == null
              ? this.datosFinca.ENTE_ID
              : this.pagos.ENTE_ID,
          fecha_inicio: this.pagos.FECHA_INICIO,
          fecha_fin: this.pagos.FECHA_FIN,
          observaciones: this.pagos.OBSERVACIONES,
          estado: this.pagos.ESTADO,
        },
      })
        .then((res) => {
          //this.formTitulo = 'Finca: ' + this.datosFinca.ENTE_NOM_COMER
          //console.log(res.data.datosFinca)
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cambiarContrasenaUsuario() {
      console.log(this.duser);
      if (!this.$refs.formContrasenaUsuario.validate()) {
        return;
      }
      this.setUrl("api/guardar-password");
      this.requestApi({
        method: "POST",
        data: {
          username: this.duser.userName,
          password: this.duser.password,
        },
      })
        .then((res) => {
          //console.log(res.data.dae)

          this.dialogContrasenaUsuario = false;
        })
        .then(() => {});
    },

    abrirFormUsuarioContrasena(du) {
      this.duser.nombres = this.datosFinca.ENTE_NOMBRE;
      this.duser.userName = this.datosFinca.USER_NAME;
      this.duser.password = "";
      this.duser.password2 = "";
      this.dialogContrasenaUsuario = true;
    },

    handlePageChange() {
      this.cargarLista();
    },
    abrirDialogContacto(item) {
      if (item === null) {
        this.datosContacto = {
          CONTACTO_ID: "",
          TIPO_ENTE_ID: 1,
          CLIENTE_ID: this.datosFinca.ENTE_ID,
        };
        this.dialogContacto = true;
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/contacto-finca");
      this.requestApi({
        method: "GET",
        data: {
          contacto_id: item.CONTACTO_ID,
        },
      })
        .then((res) => {
          //this.formTitulo = 'Finca: ' + this.datosFinca.ENTE_NOM_COMER
          //console.log(res.data.datosFinca)
          this.datosContacto = res.data.datosContacto;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    crearCotacto() {
      if (!this.$refs.formContacto.validate()) {
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/crear-contacto-finca");
      this.requestApi({
        method: "POST",
        data: {
          contacto: this.datosContacto,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargarDatosFinca(this.datosFinca.ENTE_ID);
          this.dialogContacto = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    modificarContacto() {
      if (!this.$refs.formContacto.validate()) {
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/modificar-contacto-finca");
      this.requestApi({
        method: "POST",
        data: {
          contacto: this.datosContacto,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargarDatosFinca(this.datosFinca.ENTE_ID);
          this.dialogContacto = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    borrarContacto(contactoId) {
      Vue.swal({
        html: "Está seguro de borrar este contacto  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-contacto-finca");
          this.requestApi({
            method: "POST",
            data: {
              contactoId: contactoId,
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.msg,
                  timer: 3000,
                },
              });
              this.cargarDatosFinca(this.datosFinca.ENTE_ID);
            })
            .catch(() => {})
            .then(() => {});
        }
      });
    },
    guardarContacto() {
      if (this.datosContacto.CONTACTO_ID != "") {
        this.modificarContacto();
      } else {
        this.crearCotacto();
      }
    },
    setPaisCodigo() {
      this.datosFinca.ENTE_PAIS_COD = this.datosFinca.ENTE_PAIS;
    },
  },
  mounted() {
    //this.$vuetify.lang.current = 'es'
    //this.cargarLista()
    document.title = "Sistema de carga";
    this.setTitleToolbar("Fincas");
  },
  watch: {
    options: {
      handler() {
        this.cargarLista();
      },
      deep: true,
    },
  },
};
</script>
